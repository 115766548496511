import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import loadable from "@loadable/component"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BreadcrumbModule = loadable(() =>
  import("../components/BreadcrumbModule/BreadcrumbModule")
)
// const ListingIntroModule = loadable(() =>
//   import("../components/ListingIntroModule/ListingIntroModule")
// )
const NewsTab = loadable(() => import("../components/NewsTab/NewsTab"))
const InnerPagination = loadable(() =>
  import("../components/InnerPagination/InnerPagination")
)
const NewsletterModule = loadable(() =>
  import("../components/ValuationModule/NewsletterModule")
)

const News = ({ data }, props) => {
  const PageData = data?.strapiPage
  const allNews = data?.allStrapiBlog.edges
  // const elfData = data?.site.siteMetadata?.elfSight
  return (
    <Layout
      popularSearch={PageData?.select_popular_search?.title}
      customClass={
        PageData?.custom_css_classname ? PageData?.custom_css_classname : ""
      }
    >
      <div className="layout-padding-top">
        {/* <BreadcrumbModule
          parentname={PageData.choose_menu[0]?.strapi_parent?.title}
          parent={PageData.choose_menu[0]?.strapi_parent?.slug}
          pagename={PageData.title}
        /> */}

        <div className="grey-bg">
          <NewsTab data={allNews} />
          <InnerPagination />
          {PageData?.add_page_modules?.length > 0 &&
            PageData.add_page_modules?.map((module, index) => {
              return (
                <>
                  {module.strapi_component ===
                    "page-modules.image-and-content" &&
                    module.subscribe_form && (
                      <NewsletterModule
                        {...module}
                        id={PageData.strapi_id}
                        imagetransforms={PageData.imagetransforms}
                        ggfx_results={PageData.ggfx_results}
                      />
                    )}
                </>
              )
            })}
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  return (
    <SEO
      title={
        PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title
      }
      description={
        PageData?.seo?.metaDescription
          ? PageData?.seo?.metaDescription
          : PageData?.title
      }
    />
  )
}

export default News

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_AND_CONTENT {
          ...ImageAndContentFragment
        }
      }
    }
    allStrapiBlog(
      filter: { publish: { eq: true } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          date(formatString: "DD MMM, yyyy")
          title
          slug
          strapi_id
          tile_image {
            url
          }
          ggfx_results {
            src_cftle
            transforms {
              url
              transform
              format
            }
          }
          category {
            strapi_json_value
          }
          blog_content {
            ... on STRAPI__COMPONENT_COMPONENTS_CONTENT {
              strapi_component
              content {
                data {
                  content
                }
              }
            }
           }
          imagetransforms {
            tile_image_Transforms
          }
        }
      }
    }
  }
`
