/**
 * SEO component that queries for data with
 * Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
const { useLocation } = require("@reach/router")
function Seo({ description, title, children, img, customCanonical }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )
  let { pathname } = useLocation()
  if (pathname === "/home/" || pathname === "/home") {
    pathname = "/"
  }

  let currentUrl = ""
  currentUrl = customCanonical ? customCanonical : process.env.GATSBY_SITE_URL + pathname
  // check trailing slash
  if (!currentUrl.endsWith("/")) currentUrl += "/"

  // remove page- from url
  if (currentUrl.includes("page-")) {
    currentUrl = currentUrl
      .split("/")
      .filter((path) => !path.includes("page-"))
      .join("/")
  }

  // remove multiple slashes
  currentUrl = currentUrl.replace(/([^:]\/)\/+/g, "$1")

  // set lowercase url
  currentUrl = currentUrl.toLowerCase()

  const metaDescription = description || `Find the best holiday home rentals in Dubai with ${process.env.GATSBY_SITE_NAME}. Enjoy luxurious accommodations, prime locations, and exceptional service. Book your stay today!`
  let robots = "all"
  if (process.env.GATSBY_SITE_ENV !== "production") {
    robots = "none"
  }

  return (
    <>
      <title>{title ? `${title} | ${process.env.GATSBY_SITE_NAME}` : process.env.GATSBY_SITE_NAME}</title>
      <link rel="canonical" href={currentUrl} />

      <meta name="robots" content={robots} />
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      {img && (
        <>
          <meta name="image" property="og:image" content={img} />
          <meta property="og:image:secure_url" content={img} />
          <meta property="og:image:url" content={img} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta
            property="og:image:alt"
            content={title ? `${title} | ${process.env.GATSBY_SITE_NAME}` : process.env.GATSBY_SITE_NAME}
          />
          <meta name="twitter:image" content={img} />
        </>
      )}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="providentestate.com" />
      <meta name="twitter:creator" content={process.env.GATSBY_SITE_NAME || ""} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta property="twitter:image" content={img} />
      <meta name="twitter:url" content={currentUrl} />
      {children}
    </>
  )
}

export default Seo
